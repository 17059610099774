import { addEmailRecord } from './airtable-handler'
import { Modal } from 'bootstrap'

const modal = document.getElementById('exampleModal') as HTMLElement
const modalInstance = new Modal(modal)

const modelTriggerer = (text: string) => {
  modal?.addEventListener('show.bs.modal', () => {
    const modalTitle = modal.querySelector('.modal-title')
    if (modalTitle) {
      modalTitle.textContent = text
    }
  })
  modalInstance.show()
}

export const modalHider = () => {
  modalInstance.hide()
}

const handleEmailReg = async (email: any) => {
  const isRegistered = await addEmailRecord(email)
  if (isRegistered && isRegistered === 1) {
    modelTriggerer('Got it! see you soon!')
    return
  } else if (isRegistered && isRegistered === 2) {
    modelTriggerer(
      'Oops! an exception occured, you can contact us at ksingh@regaxis.com'
    )
    return
  } else {
    modelTriggerer(
      'Oops! an error occured, you can contact us at ksingh@regaxis.com'
    )
    return
  }
}

const modalHiderBtn = document.getElementById('modalHider')
modalHiderBtn?.addEventListener('click', () => {
  modalHider()
})

const captureForm = document.getElementById('captureForm') as HTMLFormElement

captureForm.addEventListener('submit', async (event) => {
  event.preventDefault()

  const emailInput = document.getElementById('emailInput') as HTMLInputElement
  const email = emailInput.value
  const btnSubmmit = document.getElementById('submitBtn')
  btnSubmmit!.innerHTML = 'Submitting ...'
  await handleEmailReg(email)
  btnSubmmit!.innerHTML = "Let's talk →"
})
