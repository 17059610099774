import Airtable, { FieldSet, Records } from 'airtable'

const airtable = new Airtable({
  apiKey: process.env.AIRTABLE_API_KEY,
})

const emailRecordDb = airtable.base('appMsgNuC7tHzmvMC')('waitlisted')

export const addEmailRecord = async (email: string) => {
  const exist =
    (
      await emailRecordDb
        .select({
          filterByFormula: `{email} = '${email}'`,
          maxRecords: 1,
        })
        .all()
    ).length > 0

  if (exist) {
    return 0
  }
  let record: Records<FieldSet>
  try {
    record = await emailRecordDb.create([
      {
        fields: {
          email,
        },
      },
    ])
  } catch (e) {
    return 2
  }

  if (record.length > 0) {
    return 1
  }
}
